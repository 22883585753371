import React from 'react';

export const North = () => (
    <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 17.38">
        <g>
            <g>
                <path d="M9.48,12.22L4.95,.14c-.07-.19-.34-.19-.41,0L.01,12.22c-.07,.2,.15,.38,.33,.26l4.29-2.91c.07-.05,.17-.05,.24,0l4.29,2.91c.18,.12,.4-.06,.33-.26Z"/>
                <path d="M5.85,14.21c0,.74,.02,1.37,.08,2.02h-.01c-.22-.53-.51-1.09-.83-1.63l-1.26-2.09h-1.01v4.86h.82v-1.74c0-.8,0-1.44-.04-2.07h.02c.24,.54,.56,1.14,.87,1.67l1.27,2.14h.92v-4.86h-.82v1.7Z"/>
            </g>
        </g>
    </svg>
);
