import React from 'react';

export const Drag = () => (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-8.000000, -8.000000)" fill="#979797">
                <path d="M17,25 C18.1045695,25 19,25.8954305 19,27 C19,28.1045695 18.1045695,29 17,29 C15.8954305,29 15,28.1045695 15,27 C15,25.8954305 15.8954305,25 17,25 Z M24,25 C25.1045695,25 26,25.8954305 26,27 C26,28.1045695 25.1045695,29 24,29 C22.8954305,29 22,28.1045695 22,27 C22,25.8954305 22.8954305,25 24,25 Z M17,18 C18.1045695,18 19,18.8954305 19,20 C19,21.1045695 18.1045695,22 17,22 C15.8954305,22 15,21.1045695 15,20 C15,18.8954305 15.8954305,18 17,18 Z M24,18 C25.1045695,18 26,18.8954305 26,20 C26,21.1045695 25.1045695,22 24,22 C22.8954305,22 22,21.1045695 22,20 C22,18.8954305 22.8954305,18 24,18 Z M17,11 C18.1045695,11 19,11.8954305 19,13 C19,14.1045695 18.1045695,15 17,15 C15.8954305,15 15,14.1045695 15,13 C15,11.8954305 15.8954305,11 17,11 Z M24,11 C25.1045695,11 26,11.8954305 26,13 C26,14.1045695 25.1045695,15 24,15 C22.8954305,15 22,14.1045695 22,13 C22,11.8954305 22.8954305,11 24,11 Z" id="Combined-Shape"></path>
            </g>
        </g>
    </svg>
);
