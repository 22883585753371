import React from 'react';

export const Layers = () => (
    <svg width="1em" height="1em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-10.000000, -10.000000)">
                <path d="M26.822,20.6490471 L27.6897848,21.1075254 C27.9339631,21.2364889 28.0273631,21.5389801 27.8983996,21.7831583 C27.8514267,21.8720963 27.7787228,21.9448002 27.6897848,21.9917731 L20.4670182,25.8064996 C20.1747992,25.960836 19.8252008,25.960836 19.5329818,25.8064996 L12.3102152,21.9917731 C12.0660369,21.8628096 11.9726369,21.5603184 12.1016004,21.3161402 C12.1485733,21.2272022 12.2212772,21.1544983 12.3102152,21.1075254 L13.178,20.6490471 L20,24.2517537 L26.822,20.6490471 Z M20.4670182,14.1403437 L27.6897848,17.9550702 C27.9339631,18.0840337 28.0273631,18.3865249 27.8983996,18.6307031 C27.8514267,18.7196411 27.7787228,18.792345 27.6897848,18.8393179 L20.4670182,22.6540444 C20.1747992,22.8083808 19.8252008,22.8083808 19.5329818,22.6540444 L12.3102152,18.8393179 C12.0660369,18.7103544 11.9726369,18.4078632 12.1016004,18.163685 C12.1485733,18.074747 12.2212772,18.0020431 12.3102152,17.9550702 L19.5329818,14.1403437 C19.8252008,13.9860073 20.1747992,13.9860073 20.4670182,14.1403437 Z" id="Combined-Shape-Copy-2"></path>
            </g>
        </g>
    </svg>
);
